import React from 'react';
import Layout from '../components/layout';
import tournamentWithPoints from '../images/instructions/tournament-with-points.png';
import unbalancedTournamentWithPoints from '../images/instructions/unbalanced-tournament-with-points.png';
import SEO from '../components/seo';

const Advances = () => (
  <td>
    <span aria-hidden="true">✅</span>
    {' '}
    Advances
  </td>
);

const DoesNotAdvance = () => (
  <td>
    <span aria-hidden="true">❌</span>
    {' '}
    Does not advance
  </td>
);

const ScoringDetails = () => (
  <Layout>
    <SEO
      title="Scoring Details | Rules for Tournament Series Advancement"
      description="If you're in the Code Championship Tournament Series, this explains how to advance."
      keywords={['scoring', 'details', 'rules']}
    />
    <h1>Scoring Details</h1>
    <div className="stripe-hr thin" />
    <h2>Overview</h2>
    <p>
      Points are awarded to players based on how they finish
      in tournaments during the tournament series. Starting with
      1 Point awarded for making the tournament, each round of further advancement
      is worth twice as much, plus one point. The exception is winning a tournament.
      Winning a tournament in the series is worth 1000 points - enough to guarantee advancement.
    </p>
    <table className="information-table">
      <thead>
        <tr>
          <th>Tournament Finish</th>
          <th>Points Awarded</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Winner</td>
          <td>1000</td>
          <td>
            This large number of points guarantees that winning a single
            tournament will advance a coder to the next level.
          </td>
        </tr>
        <tr>
          <td>Finalist</td>
          <td>63</td>
          <td>
            Points for the second place finisher who played in the championship match
          </td>
        </tr>
        <tr>
          <td>Semi-Finalist</td>
          <td>31</td>
          <td>
            Points for those who played in a semi-final (second-to-last-round) match,
            typically top 4
          </td>
        </tr>
        <tr>
          <td>Round of 8</td>
          <td>15</td>
          <td>
            Points for those who played in the a quarter-final (third-to-last-round) match,
            typically top 8
          </td>
        </tr>
        <tr>
          <td>Round of 16</td>
          <td>7</td>
          <td>
            Points for those who played in the a fourth-to-last-round match, typically top 16
          </td>
        </tr>
        <tr>
          <td>Round of 32</td>
          <td>3</td>
          <td>
            Points for those who played in the a fifth-to-last-round match, typically top 32
          </td>
        </tr>
        <tr>
          <td>Round of 64</td>
          <td>1</td>
          <td>
            Minimum points for those who played in the tournament, typically top 64
          </td>
        </tr>
      </tbody>
    </table>
    <img
      src={tournamentWithPoints}
      style={{ width: '100%', maxWidth: '600px', margin: '10px' }}
      alt="Tournament with Points"
    />
    <h2>Example Series</h2>
    <p>
      In this example series, eight competitors are playing in a three-week series.
      The three winners of the three tournaments will advance. However, if a single player
      wins multiple tournaments, the point system decides who will advance. In this case,
      we will give an example where Maddie R wins multiple tournaments, leading to points
      being the final factor in who advances to the next level.
    </p>
    <table className="information-table">
      <thead>
        <tr>
          <th>Player Name</th>
          <th>Week 1</th>
          <th>Week 2</th>
          <th>Week 3</th>
          <th>Total</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ammarah R</td>
          <td>15</td>
          <td>63</td>
          <td>31</td>
          <td>109</td>
          <Advances />
        </tr>
        <tr>
          <td>Charlie Z</td>
          <td>15</td>
          <td>15</td>
          <td>15</td>
          <td>45</td>
          <DoesNotAdvance />
        </tr>
        <tr>
          <td>Lilly-Mai M</td>
          <td>31</td>
          <td>31</td>
          <td>15</td>
          <td>77</td>
          <DoesNotAdvance />
        </tr>
        <tr>
          <td>Daniel A</td>
          <td>63</td>
          <td>15</td>
          <td>31</td>
          <td>109</td>
          <Advances />
        </tr>
        <tr>
          <td>Dan S</td>
          <td>31</td>
          <td>15</td>
          <td>15</td>
          <td>61</td>
          <DoesNotAdvance />
        </tr>
        <tr>
          <td>Usaamah L</td>
          <td>15</td>
          <td>31</td>
          <td>1000</td>
          <td>1046</td>
          <Advances />
        </tr>
        <tr>
          <td>Maddie R</td>
          <td>1000</td>
          <td>1000</td>
          <td>63</td>
          <td>2063</td>
          <Advances />
        </tr>
        <tr>
          <td>Rowan J</td>
          <td>15</td>
          <td>15</td>
          <td>15</td>
          <td>45</td>
          <DoesNotAdvance />
        </tr>
      </tbody>
    </table>
    <h2>Important Notes</h2>
    <ul>
      <li>
        Points do not compound for a single tournament,
        meaning that first place winner does not receive 1000 points, plus 63 points for
        making the Round of 2, plus 31 points for making the round of 4 etc.
        That has already been taken into account, so first place takes 1000
        points for the single tournament.
      </li>
      <li>
        Points accumulate throughout the tournament series, so a player who takes 3rd place
        in each tournament during the series would end the series
        with a higher score than a player
        who took second in a single tournament and then 64th in all other tournaments.
      </li>
      <li>
        If two players are tied on points for the last spot to advance to the next level,
        both players advance to the next level.
      </li>
      <li>
        An unbalanced tournament (where any player is given a bye), may mean that there may not be
        a player in all positions.
        Points are still awarded by finishing round.
        For example, the tournament below does not have a 4th place player,
        so only Dan S is awarded 31 points.
        <br />
        <img
          src={unbalancedTournamentWithPoints}
          style={{ width: '100%', maxWidth: '500px', margin: '10px' }}
          alt="Unbalanced Tournament with Points"
        />
        { }
      </li>
    </ul>
    <h2>Background and Feedback</h2>
    <p>
      We used to advance only those who won a tournament during a series,
      but when a few players continued to win tournaments, each additional
      win really felt like a loss for everyone. Even our best players want
      other great players to make it to the next level. This updated scoring
      is our way of allowing more pathways to the next level.
    </p>
    <p>
      We are always open to feedback and we plan to continue iterating on
      this scoring process. Please let us know what you think!
    </p>
  </Layout>
);

export default ScoringDetails;
